var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": "",
      "id": "page"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.clickNewAccount
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New User")], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "white rounded mt-5",
    staticStyle: {
      "max-width": "1300px",
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-data-table', {
    staticClass: "transparent",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.users,
      "search": _vm.filter,
      "custom-filter": _vm.usersFiltered
    },
    scopedSlots: _vm._u([_vm.isAdmin ? {
      key: "top",
      fn: function fn() {
        return [_c('v-sheet', {
          staticClass: "primary px-3 pt-3 red--text"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "rows": "12",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dark": "",
            "dense": "",
            "rounded": "",
            "outlined": "",
            "hide-details": "",
            "placeholder": "name, account, email",
            "prepend-icon": "mdi-magnify"
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    } : null, {
      key: "item.updateButton",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openEditForm(item.xid);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-card-account-details-outline")])], 1), _c('v-btn', {
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.password_reset(item.username);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-lock-reset")])], 1)];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.account.business) + " ")];
      }
    }, {
      key: "item.permission",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getAccountPermissionName(item)) + " ")];
      }
    }], null, true)
  })], 1), _vm.editUser ? _c('v-dialog', {
    attrs: {
      "max-width": "700",
      "persistent": ""
    },
    model: {
      value: _vm.showEditForm,
      callback: function callback($$v) {
        _vm.showEditForm = $$v;
      },
      expression: "showEditForm"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editFormLoading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update User ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Username"
    },
    model: {
      value: _vm.editUser.username,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "username", $$v);
      },
      expression: "editUser.username"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "First Name"
    },
    model: {
      value: _vm.editUser.name_first,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "name_first", $$v);
      },
      expression: "editUser.name_first"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Last Name"
    },
    model: {
      value: _vm.editUser.name_last,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "name_last", $$v);
      },
      expression: "editUser.name_last"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Email Address"
    },
    model: {
      value: _vm.editUser.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "email", $$v);
      },
      expression: "editUser.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Account",
      "items": _vm.availableSettings,
      "item-text": "business",
      "item-value": "xid"
    },
    on: {
      "change": function change($event) {
        _vm.editUser.permission = null;
      }
    },
    model: {
      value: _vm.editUser.account,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "account", $$v);
      },
      expression: "editUser.account"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Module Access",
      "items": _vm.editUserPermissions,
      "item-text": "title",
      "item-value": "xid"
    },
    model: {
      value: _vm.editUser.permission,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "permission", $$v);
      },
      expression: "editUser.permission"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": _vm.closeEditForm
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.clickUpdate
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }