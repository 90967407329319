<template>
  <v-container fluid v-if='LOGGED_IN' id='page' class='pt-0 overflow-y-auto'>

    <v-row>
      <v-col>
        <v-btn class='primary' @click='clickNewAccount'><v-icon>mdi-plus</v-icon>New User</v-btn>
      </v-col>
    </v-row>

    <v-sheet rounded elevation="2" class='white rounded mt-5' style='max-width:1300px;overflow:hidden;'>
      <v-data-table
        :headers='headers'
        :items='users'
        :search='filter'
        :custom-filter='usersFiltered'
        class='transparent'
      >
      <template v-slot:top v-if='isAdmin'>
        <v-sheet class='primary px-3 pt-3 red--text'>
          <v-row>
            <v-col rows=12 md=6 lg=4 xl=3>
              <v-text-field v-model='filter' dark dense rounded outlined hide-details placeholder='name, account, email' prepend-icon="mdi-magnify" />
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:item.updateButton="{ item }">
        <v-btn fab small text @click='openEditForm(item.xid)'><v-icon class='primary--text'>mdi-card-account-details-outline</v-icon></v-btn>
        <v-btn fab small text @click='password_reset(item.username)'><v-icon class='primary--text'>mdi-lock-reset</v-icon></v-btn>
      </template>
      <template v-slot:item.account="{ item }">
        {{item.account.business}}
      </template>
      <template v-slot:item.permission="{ item }">
        {{getAccountPermissionName(item)}}
      </template>
    </v-data-table>
  </v-sheet>


  <v-dialog v-model="showEditForm" v-if='editUser' max-width="700" persistent>
    <v-card elevation="20" :disabled='editFormLoading'>
      <v-card-title class="text-h6 primary white--text py-3">
        Update User
      </v-card-title>
      <v-card-text class='pt-8 text-subtitle-2'>
        <v-row style='py-0'>
          <v-col cols=12 lg=6 style='py-0'><v-text-field v-model='editUser.username' rounded outlined dense hide-details label='Username'/></v-col>
        </v-row><v-row>
          <v-col cols=12 lg=6 style='py-0'><v-text-field v-model='editUser.name_first' rounded outlined dense hide-details label='First Name'/></v-col>
          <v-col cols=12 lg=6 style='py-0'><v-text-field v-model='editUser.name_last' rounded outlined dense hide-details label='Last Name'/></v-col>
          <v-col cols=12><v-text-field v-model='editUser.email' rounded outlined dense hide-details label='Email Address' /></v-col>
          <v-col cols=12 lg=6><v-select v-model='editUser.account' rounded outlined dense hide-details label='Account' :items='availableSettings' item-text='business' item-value='xid' @change='editUser.permission = null' /></v-col>
          <v-col cols=12 lg=6><v-select v-model='editUser.permission' rounded outlined dense hide-details label='Module Access' :items='editUserPermissions' item-text='title' item-value='xid' /></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class='pb-4'>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" @click='closeEditForm'>Cancel</v-btn>
        <v-btn color="primary darken-1" @click='clickUpdate'>Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  </v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
  }
}

</style>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Users',

  components: {
  },

  data: () => ({
    users: [],
    showEditForm: false,
    editUser: null,
    availableSettings: [],
    editOKButton: "Save",
    editFormLoading: false,
    filter: ""
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES']),

    editUserPermissions(){
      return this.availableSettings.find(acc => acc.xid == this.editUser.account)?.permissions ?? [];
    },

    isAdmin(){
      let codes = this.MODULES ? this.MODULES.map(m => m.code) : [];
      return codes.includes('admin_users');
    },

    headers(){
      let accountsIfAdmin = this.isAdmin ? [{ text: 'Account', value: 'account' }] : [];
      return [
        { text: 'User', value: 'username' },
        { text: 'First Name', value: 'name_first' },
        { text: 'Last Name', value: 'name_last' },
        ...accountsIfAdmin,
        { text: 'Module Access', value: 'permission' },
        { text: 'Update', value: 'updateButton' },
      ];
    },

  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'CONFIRM', 'ALERT']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    fetchUsers(){
      return this.API({ method: 'GET', endpoint: 'users' })
      .then(users => {
        this.users = users;
      });
    },

    loadAvailableUserSettings(){
      return this.API({ endpoint: 'core/auth/users/account_settings_available', data: { token: this.TOKEN } })
      .then((data)=> {
        this.availableSettings = data;
      });
    },

    clickNewAccount(){
      this.API({ method: 'GET', endpoint: 'users/create'})
      .then(userId => {
        this.fetchUsers()
        .then(()=>{
          this.openEditForm(userId);
        });
      });
    },

    closeEditForm(){
      this.showEditForm = false;
      this.editAccountName = "";
      this.editAccountId = null;
      this.editModuleGroup = null;
    },

    openEditForm(userId){
      this.editUser = { ...this.users.find(u=>u.xid == userId) };
      this.editUser.account = this.editUser.account.xid;
      this.editUser.permission = this.editUser.permission?.xid || null;
      this.showEditForm = true;
    },

    getAccountPermissionName(item){
      let account = this.availableSettings.find(acc => acc.xid == item.account.xid);
      let permissions = account.permissions;
      let permission = item.permission?.xid;
      return permissions.find(p => p.xid == permission).title;
    },

    clickUpdate(){
      this.editFormLoading = true;
      this.API({ method: 'PATCH', endpoint: 'users/'+this.editUser.xid, data: {
        username: this.editUser.username,
        first_name: this.editUser.name_first,
        last_name: this.editUser.name_last,
        email: this.editUser.email,
        account: this.editUser.account,
        module_access: this.editUser.permission,
      }}).then(()=>{
        this.closeEditForm();
        this.fetchUsers();
      }).finally(()=>{ this.editFormLoading = false; });
    },

    usersFiltered(value, search, item){
      let ret = true;
      let accountname = this.availableSettings.find(acc => acc.xid == item.account)?.business ?? "";
      search.toLowerCase().split(' ').forEach(s => {
        if(
          item.name_first.toLowerCase().includes(s) == false &&
          item.name_last.toLowerCase().includes(s) == false &&
          item.username.toLowerCase().includes(s) == false &&
          item.email.toLowerCase().includes(s) == false &&
          accountname.toLowerCase().includes(s) == false
        ) ret = false;
      });
      return ret;
    },

    async password_reset(username){
      await this.CONFIRM({ title: 'Reset Password', message: 'The user will receive instructions to reset password via email.' });
      await this.API({ method: 'POST', endpoint: `core/auth/request_password_reset`, data: { username } });
      this.ALERT({ title: 'Reset Password', message: 'Password reset requested.' });
    }

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.loadAvailableUserSettings()
      .then(this.fetchUsers);
    });
  },

};
</script>
